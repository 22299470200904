import React from 'react'
import LiveChat from 'react-livechat'
import { useAgency } from '../hooks/use-agency'

export function LiveChatWidget() {
  const { settings } = useAgency()
  if (!settings.livechat_app_id) {
    return null
  }
  return <LiveChat license={settings.livechat_app_id} />
}
