import { addLocaleData } from 'react-intl'

export const getLanguageFromPath = (path, languages) => {
  if (path) {
    const langPart = path.split('/')[1]
    return languages.includes(langPart) ? langPart : null
  }
}

export const addLocaleDataFor = (languages) =>
  languages.forEach((language) =>
    addLocaleData(...require(`react-intl/locale-data/${language}`))
  )
