import React from 'react'
import { useLocalStorage } from 'react-use'
import { FormattedMessage } from 'react-intl'
import { useAgency } from '../hooks/use-agency'
import Link from './localized-link'

export function CookieBanner() {
  const { agency } = useAgency()
  const [acceptedCookies, setAcceptedCookies] = useLocalStorage(
    `accepted_cookies#${agency.id}`,
    false
  )

  return (
    !acceptedCookies && (
      <div className="c-sticky-alert c-sticky-alert--left print-display-none cookieWarning">
        <p>
          <FormattedMessage
            id="page.cookie-warning"
            values={{
              company: agency.name,
              privacyLink: (
                <Link to="/privacy">
                  <FormattedMessage id="page.privacy" />
                </Link>
              ),
            }}
          />
        </p>
        <button
          className="c-sticky-alert__close closeCookieWarning"
          onClick={() => {
            setAcceptedCookies(true)
          }}
        >
          ×
        </button>
      </div>
    )
  )
}
